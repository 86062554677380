import React from "react";
import moment from "moment";

export const bets_columns = [
  {
    title: "Bet ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Bet Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Combination",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Draw",
    dataIndex: "drawNumber",
    key: "drawNumber",
  },
  {
    title: "Agent",
    render: (item) => <p>{item.user.username}</p>,
    key: "agent",
  },
  {
    title: "Time",
    key: "time",
    render: (item) => (
      <p>{moment(item.created_at).format("MMMM DD,YYYY hh:mm:ss a")}</p>
    ),
  },
];
