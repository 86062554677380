import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import { LOGIN } from "../../lib/mutations";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

const Registration = (props) => {
  const [form] = Form.useForm();

  const history = useHistory();

  const [login, { loading }] = useMutation(LOGIN);

  const handleFinishForm = async (data) => {
    const res = await login({
      variables: {
        object: {
          ...data,
          type: "admin",
        },
      },
    });
    const login_data = res?.data?.login;
    if (login_data?.code == 200) {
      localStorage.setItem(process.env.REACT_APP_LS_TOKEN, login_data?.token);
      //window.location.href = "/dashboard";
      history.push("/dashboard");
    } else {
      notification.error({
        message: "Login failed",
        description: "Incorrect username or password",
      });
    }
  };

  const pageValues = {
    form,
    handleFinishForm,
    loading,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView />
    </PageContext.Provider>
  );
};

export default Registration;
