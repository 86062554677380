import React, { useContext, useState } from "react";
import { PageContext } from "../../lib/context";
import { CSVLink } from "react-csv";
import { formatMoney, calculateWinnersAndWinnings } from "../../lib/util";

export const ExportCsvData = (props) => {
  const { daily_bets, weekly_bets, monthly_bets } = useContext(PageContext);
  const [exportData, setExportData] = useState([]);

  var data = [["Date", "Agent", "Agent Type" , "Sales", "Winners", "Winnings", "Remittance"]];

  const handleClick = () => {
    if (props.title === "daily") {
      handleExportData(props.data);
    } else if (props.title === "weekly") {
      handleExportData(props.data);
    } else if (props.title === "monthly") {
      handleExportData(props.data);
    } else if (props.title === "betWinners") {
      handleExportBetWinners();
    } else if (props.title === "bets") {
      handleExportBets();
    }
  };

  const handleExportData = (bets) => {
    bets.map((item) => {
      data.push([
        item.date,
        item.username,
        item.agent_type,
        item.sales,
        item.winners,
        item.winnings,
        item.remittance,
      ]);
    });

    data.push([""]);
    data.push([
      "TOTALS",
      "", "",
      props.totalSales,
      props.totalWinners,
      props.totalWinnings,
      props.totalRemittance,
    ]);
    setExportData(data);
  };

  const handleExportBetWinners = () => {
    var data = [["Agent", "Agent Type" , "Date", "Number", "Type", "Amount", "Payable"]];
    props.betWinners.map((item) => {
      var betwinnernumber= '=""' + item.number + '""';
      data.push([
        item.agent,
        item.agent_type,
        item.date,
        betwinnernumber,
        item.type,
        item.amount,
        item.payable,
      ]);
    });
    setExportData(data);
  };
  const handleExportBets = () => {
    var data = [
      ["Bet ID", "Agent", "Agent Type" , "Date", "Draw", "Number", "Type", "Amount"],
    ];
    props.bets.map((item) => {
      var number= '=""' + item.number + '""';
      data.push([
        item.id,
        item.agent,
        item.agent_type,
        item.date,
        item.drawNumber,
        number,
        item.type,
        item.amount,
      ]);
    });
    data.push([]);
    data.push(["TOTAL", props.totalSales]);
    setExportData(data);
  };

  return (
    <div>
      <div className="float-right">
        <button className="text-xs font-bold mb-1" onClick={handleClick}>
          <CSVLink data={exportData} filename={props.filename}>
            Download CSV
          </CSVLink>
        </button>
      </div>
    </div>
  );
};
