import React, { useContext, useMemo, useState } from "react";
import { padStart } from "lodash";
import { Button, Form, Input, Modal, notification, Select } from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import { CREATE_AGENT, CREATE_BULK_AGENT, UPDATE_USER,  } from "../../../lib/mutations";
import {
  GET_BULK_AGENTS_NUMBER,
} from "../../../lib/subscriptions";

const { Option } = Select;

export const CreateBulkUser = () => {
  const { visible, setVisible } = useContext(PageContext);

  const [createAgent] = useMutation(CREATE_BULK_AGENT);
  const { data: bulkAgentsNumber } = useSubscription(GET_BULK_AGENTS_NUMBER);
  const bcrypt = require("bcryptjs");

  const [form] = Form.useForm();

  let dataArray = bulkAgentsNumber?.users?.[0]?.username?.split("");
  let newarray = [dataArray?.[1], dataArray?.[2], dataArray?.[3]].join("");

  const addBulkAgent = async (data) => {
    let count = 1;
    let agentCount = parseInt(newarray) + 1;
    let agentNum = 4;
    var objects = [];

    for (count = 1; count <= data?.number; count++) {
      let agent = agentCount.toString().padStart(3, "0");
      let agentName = agentNum.toString() + agent;

      const hash = bcrypt.hashSync(agentName, 10);

      let user = {
        firstname: agentName,
        lastname:  agentName,
        password:  hash,
        username:  agentName,
        agent_type: data?.agent_type
      };
      objects.push(user);

      if (agent === "999") {
        agentCount = 0;
        agentNum++;
      } else {
        agentCount++;
      }
      if (count == data?.number) {
        const response = await createAgent({
          variables: {
            objects: [...objects],
          },
        });

        if (response?.data?.insert_users?.returning?.[0]?.username != null) {
          notification.open({
            message: "Successfully created agent",
            type: "success",
          });
          form.resetFields();
          setVisible(false);
        } else {
          notification.open({
            message: "Failed to create agent",
            type: "error",
          });
        }
      }
    }
  };

  return (
    <Modal
      visible={visible.bulkagent}
      title="Create bulk agent"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setVisible({ ...visible, bulkagent: false })}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={addBulkAgent}>
        <Form.Item
          name="number"
          label="Number"
          required
          rules={[{ required: true }]}
        >
          <Input placeholder="Input number of agents" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="agent_type"
          label="Agent Type"
          required
        >
          <Select placeholder="Select agent type">
            <Option value="usher">Usher</Option>
            <Option value="outlet">Outlet</Option>
          </Select>
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </Form>
    </Modal>
  );
};
