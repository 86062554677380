import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Card } from "antd";


const RegistrationView = (props) => {
  const { form, handleFinishForm, loading } = useContext(PageContext);
  return (
    <div className="flex flex-row h-screen">
      <div
        className="flex flex-col h-full justify-center items-center w-full"
        style={{
          backgroundColor: 'rgba(30, 58, 138, 0.9)',
        }}
      >
        <div className="flex bg-white h-4/5 w-1/5 my-32 rounded-lg shadow-2xl">
          <Card className="flex flex-col rounded-lg w-full items-center">
            <div className="flex flex-col justify-center items-center space-y-2">
              <div className="flex flex-row space-x-2">
                <img src="./crown.png" width={50} height={24}></img>
                <p className="font-bold text-4xl text-red-700">Crown Lottery</p>
              </div>
              <div>
                <p className="font-bold text-base mb-5 secondary-color">
                  Admin Portal
                </p>
              </div>
            </div>

            <div className="mt-4">
              <Form className="flex flex-col items-center" layout="vertical" form={form} onFinish={handleFinishForm}>
                <div className="w-4/5">
                  <Form.Item label="Username" name="username" required>
                    <Input placeholder="Enter username" />
                  </Form.Item>
                  <Form.Item label="Password" name="password" required>
                    <Input.Password placeholder="password" />
                  </Form.Item>
                </div>
                <div>
                  <Button
                    className="w-full mt-5"
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    {loading ? <Spin /> : "Login"}
                  </Button>
                </div>
              </Form>
            </div>

            {/* <p className="font-bold text-base mb-5 secondary-color">
                  Admin Portal
                </p> */}


          </Card>

        </div>

        {/* <div className="flex items-center justify-center bg-white h-full w-4/5 my-32 rounded-lg">
          <Card className="flex flex-row rounded-lg w-3/5 justify-center items-center">
            <div className="flex flex-row">
              <div className="flex flex-col">
                <p className="font-bold text-xl primary-color">Crown Lottery</p>
                <p className="font-bold text-base mb-5 secondary-color">
                  Admin Portal
                </p>
              </div>
            </div>
            <div>
              <Form layout="vertical" form={form} onFinish={handleFinishForm}>
                <Form.Item label="Username" name="username" required>
                  <Input placeholder="Enter username" />
                </Form.Item>
                <Form.Item label="Password" name="password" required>
                  <Input.Password placeholder="password" />
                </Form.Item>
                <Button
                  className="w-full"
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? <Spin /> : "Login"}
                </Button>
              </Form>
            </div>
          </Card>
          <div
            className="flex flex-col h-full justify-center items-center w-2/5"
            style={{
              backgroundImage: `url(${require("../../assets/login2.jpg")})`,
              backgroundSize: "cover",
              borderRadius: 6,
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default RegistrationView;
