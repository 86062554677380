import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { PageContext } from "../../../lib/context";
import { Spin, Table } from "antd";
import {
  formatMoney,
  calculateWinnersAndWinnings,
  getTotalWinnersAndWinnings,
} from "../../../lib/util";
import { ExportCsvData } from "../exportCsvData";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { GET_AGENT_BETS } from "../../../lib/query";
import _ from "lodash";

export const Monthly = () => {
  const { getColumnSearchProps, filters } = useContext(PageContext);
  const [totals, setTotals] = useState({
    bets: 0,
    betCount: 0,
    winners: 0,
    winnings: 0,
  });

  const [tableData, setTableData] = useState([]);

  const { data: monthly_bets, loading: loading_monthly_bets } = useQuery(
    GET_AGENT_BETS,
    {
      variables: {
        resultsWhere: {
          _and: [
            { date: { _gt: moment(filters.dateFrom).format("YYYY-MM-DD") } },
            {
              date: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
        },
        betsWhere: {
          _and: [
            {
              created_at: {
                _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
              },
            },
            {
              created_at: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
          user:
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},
        },
        usersWhere: {
          _and: [
            { type: { _neq: "admin" } },
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},
            filters?.agent_type !== null ? { agent_type: { _eq: filters.agent_type } } : {},
          ],
        },
      },
    }
  );

  const handleData = (start, end, payload) => {
    let total = 0;
    let count = 0;
    let winners = 0;
    let winnings = 0;
    let remittance = 0;

    monthly_bets.users.map((user) => {
      const type = user.agent_type;
      const results = _.filter(monthly_bets?.results, (o) =>
        moment(moment(o.date).format("YYYY-MM-DD")).isBetween(
          moment(start).subtract(1, "days"),
          moment(end).add(1, "days")
        )
      );
      const bets = _.filter(user.bets, (o) =>
        moment(moment(o.created_at).format("YYYY-MM-DD")).isBetween(
          moment(start).subtract(1, "days"),
          moment(end).add(1, "days")
        )
      );
      const calcWinners = calculateWinnersAndWinnings(
        monthly_bets?.serverConfigs,
        results,
        bets,
        type
      ).totalWinners;
      const calcWinnings = calculateWinnersAndWinnings(
        monthly_bets?.serverConfigs,
        results,
        bets,
        type
      ).totalWinnings;

      const data = {
        date: `${start} - ${end}`,
        username: user.username,
        agent_type: user.agent_type,
        sales: calculateWinnersAndWinnings(
          monthly_bets?.serverConfigs,
          results,
          bets,
          type
        ).totalSales,
        winners: calcWinners,
        winnings: calcWinnings,
        remittance:
          calculateWinnersAndWinnings(
            monthly_bets?.serverConfigs,
            results,
            bets,
            type
          ).totalWinnings > 0
            ? calculateWinnersAndWinnings(
                monthly_bets?.serverConfigs,
                results,
                bets,
                type
              ).totalSales -
              calculateWinnersAndWinnings(
                monthly_bets?.serverConfigs,
                results,
                bets,
                type
              ).totalWinnings
            : 0,
      };
      total = total + parseFloat(data.sales);
      winners = winners + calcWinners;
      winnings = winnings + parseFloat(calcWinnings);
      remittance = remittance + data.remittance;
      count = count + bets.length;

      payload.push(data);
    });

    return { total, winnings, winners, count, remittance };
  };

  useEffect(() => {
    if (monthly_bets) {
      const payload = [];
      let total = 0;
      let count = 0;
      let winners = 0;
      let winnings = 0;
      let remittance = 0;

      const from = moment(filters.dateFrom).format("YYYY-MM-DD");
      const to = moment(filters.dateTo).format("YYYY-MM-DD");

      // get months for range
      var timeValues = [];
      var dateStart = moment(from);
      var dateEnd = moment(to);
      var interim = dateStart.clone();
      var timeValues = [];

      while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
        timeValues.push(interim.format("YYYY-MM"));
        interim.add(1, "month");
      }
      let start = from;
      let end = to;

      if (timeValues.length > 1) {
        for (var i = 0; i < timeValues.length; i++) {
          if (i === 0) {
            end = moment(timeValues[i]).endOf("month").format("YYYY-MM-DD");
            const {
              total: t,
              count: c,
              winners: w,
              winnings: ws,
              remittance: rm,
            } = handleData(start, end, payload);
            total = total + t;
            winners = winners + w;
            winnings = winnings + ws;
            remittance = remittance + rm;
            count = count + c;
          }

          if (i !== 0 && i !== timeValues.length - 1) {
            end = moment(timeValues[i]).endOf("month").format("YYYY-MM-DD");
            start = moment(timeValues[i]).startOf("month").format("YYYY-MM-DD");

            const {
              total: t,
              count: c,
              winners: w,
              winnings: ws,
              remittance: rm,
            } = handleData(start, end, payload);
            total = total + t;
            winners = winners + w;
            winnings = winnings + ws;
            remittance = remittance + rm;
            count = count + c;
          }

          if (i === timeValues.length - 1) {
            end = to;
            start = moment(timeValues[i]).startOf("month").format("YYYY-MM-DD");

            const {
              total: t,
              count: c,
              winners: w,
              winnings: ws,
              remittance: rm,
            } = handleData(start, end, payload);
            total = total + t;
            winners = winners + w;
            winnings = winnings + ws;
            remittance = remittance + rm;
            count = count + c;

            setTableData(payload);
            setTotals({
              bets: total,
              betCount: count,
              winnings,
              winners,
              remittance: remittance,
            });
          }
        }
      }
      if (from === to || timeValues.length === 1) {
        const {
          total: t,
          count: c,
          winners: w,
          winnings: ws,
          remittance: rm,
        } = handleData(from, to, payload);

        total = total + t;
        winners = winners + w;
        winnings = winnings + ws;
        remittance = remittance + rm;
        count = count + c;

        setTableData(payload);
        setTotals({
          bets: total,
          betCount: count,
          winnings,
          winners,
          remittance: remittance,
        });
      }
    }
  }, [monthly_bets, filters]);

  const monthly_sales = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Agent",
      key: "agent",
      dataIndex: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Agent Type",
      key: "agent_type",
      dataIndex: "agent_type",
      ...getColumnSearchProps("agent_type"),
    },
    {
      title: "Sales",
      key: "sales",
      dataIndex: "sales",
      sorter: {
        compare: (a, b) => {
          return a.sales - b.sales;
        },
        multiple: 3,
      },
    },
    {
      title: "Winners",
      key: "winners",
      dataIndex: "winners",
      sorter: {
        compare: (a, b) => {
          return a.winners - b.winners;
        },
        multiple: 3,
      },
    },
    {
      title: "Winnings",
      key: "winnings",
      dataIndex: "winnings",
      sorter: {
        compare: (a, b) => {
          return a.winnings - b.winnings;
        },
        multiple: 3,
      },
    },
    {
      title: "Remittance",
      key: "remittance",
      dataIndex: "remittance",
      sorter: {
        compare: (a, b) => {
          return a.remittance - b.remittance;
        },
        multiple: 3,
      },
    },
  ];

  const monthly_sales_total_title = () => {
    return (
      <div className="text-xs font-bold">
        TOTAL SALES: {totals.bets.toFixed(2)}
        <br />
        TOTAL BET COUNT: {totals.betCount}
      </div>
    );
  };

  return (
    <>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <ExportCsvData
            title="monthly"
            filename="Monthly.csv"
            totalSales={totals.bets}
            totalWinnings={totals.winnings}
            totalWinners={totals.winners}
            totalRemittance={totals.remittance}
            data={tableData}
          />
          <p className="text-xs font-bold mb-1">Monthly Sales</p>
          {loading_monthly_bets ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table
              dataSource={tableData}
              columns={monthly_sales}
              title={monthly_sales_total_title}
            />
          )}
        </div>
      </div>
    </>
  );
};
