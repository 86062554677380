import React, { useContext } from "react";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { PageContext } from "../../lib/context";
import { Popconfirm } from "antd";

export const bets_columns = [
  {
    title: "Bet Type",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Combination",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Amount",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Draw",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Agent",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Time",
    dataIndex: "group",
    key: "group",
  },
  // {
  //   title: "Date",
  //   render: (item) => <p>{moment(item?.created_at).format('YYYY-MM-DD HH:mm a')}</p>,
  // },
];
