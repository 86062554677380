import React from "react";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

export const agents_columns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "First name",
    dataIndex: "firstname",
    key: "firstname",
  },
  {
    title: "Last name",
    dataIndex: "lastname",
    key: "lastname",
  },
  {
    title: "Action",
    render: (item) => (
      <div>
        <EditOutlined className="cursor-pointer" />
      </div>
    ),
  },
];
