import gql from "graphql-tag";

export const LOGIN = gql`
  mutation MyMutation($object: LoginInput!) {
    login(object: $object) {
      code
      message
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation MyMutation($password: String!, $user_id: String!) {
    reset_password(object: { password: $password, user_id: $user_id }) {
      code
      message
    }
  }
`;

export const CREATE_AGENT = gql`
  mutation MyMutation(
    $firstname: String!
    $lastname: String!
    $password: String!
    $username: String!
    $email: String
    $agent_type: String!
  ) {
    create_agent(
      object: {
        firstname: $firstname
        lastname: $lastname
        password: $password
        username: $username
        email: $email
        type: "agent"
        agent_type: $agent_type
      }
    ) {
      code
      message
    }
  }
`;

export const CREATE_COORDINATOR = gql`
  mutation MyMutation(
    $firstname: String!
    $lastname: String!
    $password: String!
    $username: String!
    $email: String
    $agent_type: String!
  ) {
    create_agent(
      object: {
        firstname: $firstname
        lastname: $lastname
        password: $password
        username: $username
        email: $email
        type: "coordinator"
        agent_type: $agent_type
      }
    ) {
      code
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation(
    $id: uuid
    $firstname: String
    $lastname: String
    $username: String
    $agent_type: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { firstname: $firstname, lastname: $lastname, username: $username, agent_type: $agent_type }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_LIMIT = gql`
  mutation MyMutation($object: betsLimits_insert_input!) {
    insert_betsLimits_one(object: $object) {
      id
    }
  }
`;

export const DELETE_LIMIT = gql`
  mutation MyMutation($id: uuid, $type: String) {
    delete_betsLimits(where: { id: { _eq: $id }, type: { _eq: $type } }) {
      affected_rows
    }
  }
`;

export const UPDATE_GLOBAL = gql`
  mutation MyMutation($currentLimit: Int, $limit: Int) {
    update_betsLimits(
      where: { type: { _eq: "global" } }
      _set: { currentLimit: $currentLimit, limit: $limit }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_RESULT = gql`
  mutation MyMutation($object: results_insert_input!) {
    insert_results_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_SERVER_CONFIG = gql`
  mutation MyMutation($type: String, $value: String) {
    update_serverConfigs(
      where: { type: { _eq: $type } }
      _set: { type: $type, value: $value }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation MyMutation($id: uuid) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const CREATE_BULK_AGENT = gql`
  mutation MyMutation($objects: [users_insert_input!]!) {
    insert_users(objects: $objects) {
      returning {
        username
      }
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation MyMutation($id: uuid, $status: Boolean) {
    update_users(where: { id: { _eq: $id } }, _set: { isActivated: $status }) {
      affected_rows
    }
  }
`;

export const DELETE_RESULTS = gql`
  mutation MyMutation($id: uuid) {
    delete_results(where: {id: { _eq: $id }}) {
     returning {
        id
      }
      affected_rows
    }
  }
`;