import { useMutation } from "@apollo/react-hooks";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
} from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { INSERT_LIMIT } from "../../../lib/mutations";
import moment from "moment";

export const IndividualModal = () => {
  const {
    state,
    visible,
    setVisible,
    setState,
    individual_limits,
  } = useContext(PageContext);

  const [form] = Form.useForm();

  const [insertLimit] = useMutation(INSERT_LIMIT);

  const handleFormFinish = async (payload) => {
    payload["currentLimit"] = payload.limit;
    payload["type"] = "individual";

    if (
      individual_limits.betsLimits.find(
        (item) => item.number === payload.number
      ) &&
      individual_limits.betsLimits.find((item) => item.draw === payload.draw) &&
      individual_limits.betsLimits.find(
        (item) =>
          moment(item.date).format("MMMM DD, YYYY") ==
          moment(payload.date).format("MMMM DD, YYYY")
      )
    ) {
      message.error("Number already used");
      return;
    }

    const response = await insertLimit({
      variables: {
        object: { ...payload },
      },
    });
    if (response?.data?.insert_betsLimits_one?.id) {
      notification.open({
        message: "Successfully added individual limit",
        type: "success",
      });
      setVisible({ ...visible, limit: false });
    } else {
      notification.open({
        message: "Failed to add individual limit",
        type: "error",
      });
      setVisible({ ...visible, limit: false });
    }
  };

  return (
    <Modal
      visible={visible.limit}
      title="Add individual limit"
      onCancel={() => setVisible({ ...visible, limit: false })}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      okText="Save"
    >
      <Form form={form} onFinish={handleFormFinish} layout="vertical">
        <Form.Item
          label="Number"
          name="number"
          required
          rules={[{ required: true }]}
        >
          <Input
            maxLength={3}
            minLength={3}
            placeholder="Number"
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          label="Limit"
          name="limit"
          required
          rules={[{ required: true }]}
        >
          <InputNumber placeholder="Limit" className="w-full" />
        </Form.Item>
        <Form.Item
          label="Draw number"
          name="draw"
          required
          rules={[{ required: true }]}
        >
          <InputNumber min={1} max={3} placeholder="Draw" className="w-full" />
        </Form.Item>
        <Form.Item label="Date" name="date">
          <DatePicker className="w-full" />
        </Form.Item>
        <Alert
          showIcon
          type="info"
          message="An empty date will assume the limit is everyday. Everyday limits are refilled on 0:01 am."
        />
        <Button htmlType="submit" type="primary" className="mt-5">
          Save
        </Button>
      </Form>
    </Modal>
  );
};
