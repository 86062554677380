import React, { useState } from "react";
import { PageContext } from "../../lib/context";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import moment from "moment";
import _ from "lodash";
import { RESULTS } from "../../lib/subscriptions";
import LimitsView from "./view";
import { DELETE_RESULTS } from "../../lib/mutations";
import { notification } from "antd";

const ResultsController = () => {
  const [visible, setVisible] = useState(false);

  const [state, setState] = useState({});

  const { data: results, loading: loading_results } = useSubscription(RESULTS);

  const [deleteResult] = useMutation(DELETE_RESULTS);

  const confirm = async (payload) => {
    console.log("heyload",payload)
    const response = await deleteResult({
      variables: {
        id: payload.id,
      },
    });

    if (response?.data?.delete_results?.affected_rows > 0) {
      notification.open({
        message: "Successfully deleted result",
        type: "success",
      });
    } else {
      notification.open({
        message: "Failed to delete result",
        type: "error",
      });
    }
  };

  const pageValues = {
    results,
    loading_results,
    visible,
    setVisible,
    state,
    setState,
    confirm,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <LimitsView />
    </PageContext.Provider>
  );
};

export default ResultsController;
