import React, { useMemo } from "react";
import { PageContext } from "../../lib/context";
import _ from "lodash";
import ServerConfigurationView from "./view";
import { Form, notification } from "antd";
import moment from "moment";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import { UPDATE_SERVER_CONFIG } from "../../lib/mutations";
import { SERVER_CONFIGS } from "../../lib/subscriptions";

const ServerConfigurationController = () => {
  const [form] = Form.useForm();

  const [updateServerConfig] = useMutation(UPDATE_SERVER_CONFIG);

  const { data, loading } = useSubscription(SERVER_CONFIGS);

  useMemo(() => {
    if (data && data.serverConfigs.length) {
      const fieldsValues = {};
      data.serverConfigs.map((item) => {
        if (
          ["winning_multiplier", "winning_multiplier_same_digit","outlet_winning_multiplier","outlet_winning_multiplier_same_digit"].includes(
            item.type
          )
        )
          fieldsValues[item.type] = item.value;
        else fieldsValues[item.type] = moment(new Date(item.value));
      });
      form.setFieldsValue({
        ...fieldsValues,
      });
    }
  }, [data]);

  const handleFinishForm = async (data) => {
    const payload = {
      ...data,
      first_cut_off_start: moment(data.first_cut_off_start).format(
        "YYYY-MM-DD HH:mm"
      ),
      first_cut_off_end: moment(data.first_cut_off_end).format(
        "YYYY-MM-DD HH:mm"
      ),
      second_cut_off_start: moment(data.second_cut_off_start).format(
        "YYYY-MM-DD HH:mm"
      ),
      second_cut_off_end: moment(data.second_cut_off_end).format(
        "YYYY-MM-DD HH:mm"
      ),
      third_cut_off_start: moment(data.third_cut_off_start).format(
        "YYYY-MM-DD HH:mm"
      ),
      third_cut_off_end: moment(data.third_cut_off_end).format(
        "YYYY-MM-DD HH:mm"
      ),
    };

    const configTypes = [];
    Object.keys(payload).map((item) => {
      configTypes.push(item);
    });

    for (let i = 0; i < configTypes.length; i++) {
      const type = configTypes[i];
      const response = await updateServerConfig({
        variables: {
          type,
          value: payload[type],
        },
      });
      if (response?.data?.update_serverConfigs?.affected_rows) {
        notification.open({
          message: "Successfully updated " + type.replace(/\_/g, " "),
          type: "success",
        });
      } else {
        notification.open({
          message: "Failed to update " + type.replace(/\_/g, " "),
          type: "error",
        });
      }
    }
  };

  const pageValues = {
    handleFinishForm,
    form,
    loading,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <ServerConfigurationView />
    </PageContext.Provider>
  );
};

export default ServerConfigurationController;
