import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Spin, Table } from "antd";
import {
  WalletOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { bets_columns } from "./tables";

const TopUpDashboardView = () => {
  const {
    daily_bets,
    loading_daily,
    weekly_bets,
    loading_weekly,
    monthly_bets,
    loading_monthly,
    active_agents,
    loading_agents,
    bets,
    topups,
    loading_topups,
    agents_today
  } = useContext(PageContext);

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">TopUp Dashboard</p>
      <div className="flex flex-row mt-5">
        <div className="w-1/5 border shadow-lg mr-5 rounded-lg px-5 py-5 secondary-color">
          <div className="flex flex-row items-center">
            <UnorderedListOutlined className="mr-2 text-3xl" size={40} />
            <p className="text-base tertiary-color">Total Top Ups Today</p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {daily_bets?.coordinatorTransactions_aggregate?.aggregate?.count} Top Ups
          </p>
        </div>
        <div className="w-1/5 border shadow-lg rounded-lg px-5 py-5 mr-3 secondary-color">
          <div className="flex flex-row items-center">
            <WalletOutlined className="mr-2 text-3xl" />
            <p className="text-base tertiary-color">Daily Top Ups Amount</p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {daily_bets?.coordinatorTransactions_aggregate?.aggregate?.sum?.amount?.toFixed(2)}
          </p>
        </div>
        <div className="w-1/5 border shadow-lg rounded-lg px-5 py-5 mr-3 secondary-color">
          <div className="flex flex-row items-center">
            <WalletOutlined className="mr-2 text-3xl" />
            <p className="text-base tertiary-color">Weekly Top Ups Amount</p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {weekly_bets?.coordinatorTransactions_aggregate?.aggregate?.sum?.amount?.toFixed(2)}
          </p>
        </div>
        <div className="w-1/5 border shadow-lg rounded-lg px-5 py-5 mr-3 secondary-color">
          <div className="flex flex-row items-center">
            <WalletOutlined className="mr-2 text-3xl" />
            <p className="text-base tertiary-color">Monthly Top Ups Amount</p>
          </div>
          <p className="tertiary-color mt-5 text-base font-bold">
            {monthly_bets?.coordinatorTransactions_aggregate?.aggregate?.sum?.amount?.toFixed(2)}
          </p>
        </div> 
      </div>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <p className="text-xs font-bold">Bets list</p>
          {loading_weekly || loading_daily || loading_monthly ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table dataSource={topups?.coordinatorTransactions ?? []} columns={bets_columns} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopUpDashboardView;
