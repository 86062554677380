import React, { useMemo, useRef, useState } from "react";
import { PageContext } from "../../lib/context";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import ReportsView from "./view";
// import {
//   GET_AGENT_BETS,
//   GET_DAILY_BETS,
//   GET_WEEKLY_BETS,
//   REPORTS_BETS,
// } from "../../lib/query";
import moment from "moment";
import { AGENTS, BET_WINNERS, REPORTS_BETS } from "../../lib/subscriptions";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BetWinners } from "./components/betWinners";

const ReportsController = () => {
  const [filters, setFilters] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    draw: null,
    agent: null,
  });

  const { data: bet_winners, loading: loading_bet_winners } = useQuery(
    BET_WINNERS,
    {
      variables: {
        betsWhere: {
          _and: [
            {
              created_at: {
                _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
              },
            },
            {
              created_at: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters.agent !== null
              ? { user: { id: { _in: filters.agent } } }
              : {},
            filters.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
            filters.agent_type !== null
              ? { user: { agent_type: { _eq: filters.agent_type } } }
              : {},
          ],
        },
        resultsWhere: {
          _and: [
            {
              created_at: {
                _gte: moment().format("YYYY-MM-DD"),
              },
              //drawNumber: { _eq: filters.draw },
            },
            // filters.agent !== null
            // ? { user: { id: { _in: filters.agent } } }
            // : {},
            filters.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
        },
      },
    }
  );

  const { data: agents, loading: loading_agents } = useSubscription(AGENTS);

  const { data: bets, loading: loading_bets, error:err } = useQuery(REPORTS_BETS, {
    variables: {
      betsWhere: {
        _and: [
          {
            created_at: {
              _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
            },
          },
          {
            created_at: {
              _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
            },
          },
          filters.agent !== null
            ? { user: { id: { _in: filters.agent } } }
            : {},
          filters.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          filters.agent_type !== null
            ? { user: { agent_type: { _eq: filters.agent_type } } }
            : {},
        ],
      },
      order_by:
        filters.date !== null
          ? {
              created_at: "asc",
            }
          : {
              created_at: "desc",
            },
    },
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  console.log("err123",err)

  const handleSetFilter = ({ type, value }) => {
    switch (type) {
      case "date-from":
        if (!value) setFilters({ ...filters, dateFrom: new Date() });
        else setFilters({ ...filters, dateFrom: value });
        break;
      case "date-to":
        if (!value) setFilters({ ...filters, dateTo: new Date() });
        else setFilters({ ...filters, dateTo: value });
        break;
      case "draw":
        setFilters({ ...filters, draw: value });
        break;
      case "agent":
        setFilters({ ...filters, agent: value.length > 0 ? value : null });
        break;
      case "agent_type":
        setFilters({ ...filters, agent_type: value });
        break;
      default:
        break;
    }
  };

  const pageValues = {
    bet_winners,
    loading_bet_winners,
    handleSetFilter,
    filters,
    agents,
    bets,
    loading_bets,
    getColumnSearchProps,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <ReportsView />
    </PageContext.Provider>
  );
};

export default ReportsController;
