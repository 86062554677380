import React, { useContext, useMemo, useEffect } from "react";

import { Button, Form, Input, Row, Col, notification } from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { RESET_PASSWORD } from "../../../lib/mutations";

export const ResetPassword = () => {
    const { closePassModal, id, showForm, setShowForm } = useContext(PageContext);

    const [resetPassword] = useMutation(RESET_PASSWORD);
    const [form] = Form.useForm();

    const resetAgentPassword = async (payload) => {
        delete payload["confirmPassword"];
        const response = await resetPassword({
            variables: {
                user_id: id,
                password: payload.password,
            },
        });
        if (response?.data?.reset_password?.code == 200) {
            notification.open({
                message: "Password Reset Successfully",
                type: "success",
            });
            form.resetFields();
            closePassModal();
        } else {
            notification.open({
                message: "Failed to reset password",
                type: "error",
            });
        }
    };

    const handleFinishForm = async (payload) => {
        await resetAgentPassword(payload);
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleFinishForm}>
            <Form.Item
                name="id"
                label="id"
                hidden
            >
                <Input />
            </Form.Item>

            <Form.Item
                rules={[{ required: true }]}
                name="password"
                label="New Password"
                required
            >
                <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                required
                dependencies={["password"]}
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(
                                new Error(
                                    "The two passwords that you entered do not match!"
                                )
                            );
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Confirm password" />
            </Form.Item>
            <Row>
                <Col span={18}>
                    <Button htmlType="submit" type="primary">
                        Save
                    </Button>
                </Col>
                <Col>
                    <Button type="text" onClick={closePassModal}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
