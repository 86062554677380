import { useMutation } from "@apollo/react-hooks";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
} from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { UPDATE_GLOBAL } from "../../../lib/mutations";

export const GlobalModal = () => {
  const { state, visible, setVisible, setState } = useContext(PageContext);

  const [form] = Form.useForm();

  const [updateGlobalLimit] = useMutation(UPDATE_GLOBAL);

  const handleFormFinish = async (payload) => {
    const response = await updateGlobalLimit({
      variables: {
        currentLimit: payload.limit,
        limit: payload.limit,
      },
    });
    if (response?.data?.update_betsLimits?.affected_rows > 0) {
      notification.open({
        message: "Successfully updated global limit",
        type: "success",
      });
      form.resetFields();
      setVisible({ ...visible, global: false });
    } else {
      notification.open({
        message: "Failed to update global limit",
        type: "error",
      });
      setVisible({ ...visible, global: false });
    }
  };

  return (
    <Modal
      visible={visible.global}
      title="Update global limit"
      onCancel={() => setVisible({ ...visible, global: false })}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      okText="Save"
    >
      <Form form={form} onFinish={handleFormFinish} layout="vertical">
        <Form.Item
          label="Limit"
          name="limit"
          required
          rules={[{ required: true }]}
        >
          <InputNumber placeholder="Limit" className="w-full" />
        </Form.Item>
        <Button htmlType="submit" type="primary" className="mt-5">
          Save
        </Button>
      </Form>
    </Modal>
  );
};
