import { DatePicker, Input, Select, Tabs } from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Bets } from "./components/bets";
import { BetWinners } from "./components/betWinners";
import { Daily } from "./components/daily";
import { Monthly } from "./components/monthly";
import { Weekly } from "./components/weekly";

const { TabPane } = Tabs;

const ReportsView = () => {
  const { handleSetFilter, filters, agents } = useContext(PageContext);
  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Reports</p>
      <div className="my-5">
        <DatePicker
          placeholder="Date from"
          className="mr-5"
          onChange={(date) =>
            handleSetFilter({ type: "date-from", value: date })
          }
        />
        <DatePicker
          placeholder="Date to"
          className="mr-5"
          onChange={(date) => handleSetFilter({ type: "date-to", value: date })}
        />

        <Select
          placeholder="Select draw"
          className="mr-5 w-32"
          value={filters.draw}
          onChange={(value) => handleSetFilter({ type: "draw", value })}
        >
          <Select.Option key="1" value="1">
            1
          </Select.Option>
          <Select.Option key="2" value="2">
            2
          </Select.Option>
          <Select.Option key="3" value="3">
            3
          </Select.Option>
        </Select>

        <Select
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Agent User"
          onChange={(value) => handleSetFilter({ type: "agent", value })}
          className="mr-5 w-56"
        >
          {agents?.users?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.username}
            </Select.Option>
          ))}
        </Select>

        {/* <Select
          placeholder="Agent Type"
          className="mr-5 w-32"
          //value={filters.draw}
          onChange={(value) => handleSetFilter({ type: "agent_type", value })}
        >
          <Select.Option key="1" value="usher">
            Usher
          </Select.Option>
          <Select.Option key="2" value="outlet">
            Outlet
          </Select.Option>
        </Select> */}

        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Agent Type"
          onChange={(value) => handleSetFilter({ type: "agent_type", value })}
          className="mr-5 w-56"
        >
          <Select.Option key="usher" value="usher">
            Usher
          </Select.Option>
          <Select.Option key="outlet" value="outlet">
            Outlet
          </Select.Option>
        </Select>
      </div>
      <Tabs defaultActiveKey="1" size="large" type="card">
        <TabPane tab="Daily" key="daily">
          <Daily />
        </TabPane>
        <TabPane tab="Weekly" key="weekly">
          <Weekly />
        </TabPane>
        <TabPane tab="Monthly" key="monthly">
          <Monthly />
        </TabPane>
        <TabPane tab="BetWinners" key="betwinners">
          <BetWinners />
        </TabPane>
        <TabPane tab="Bets" key="bets">
          <Bets />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ReportsView;
