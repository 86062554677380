import React, { useState } from "react";
import { PageContext } from "../../lib/context";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import moment from "moment";
import _ from "lodash";
import {
  BETS_LIST,
  GLOBAL_LIMITS,
  INDIVIDUAL_LIMITS,
} from "../../lib/subscriptions";
import LimitsView from "./view";
import { DELETE_LIMIT } from "../../lib/mutations";
import { notification } from "antd";

const LimitsController = () => {
  const [visible, setVisible] = useState({
    limit: false,
    global: false,
  });

  const [state, setState] = useState({
    individual: {},
    global: {},
  });

  const { data: individual_limits, loading: loading_individual } =
    useSubscription(INDIVIDUAL_LIMITS);

  const { data: global_limits, loading: loading_global } =
    useSubscription(GLOBAL_LIMITS);

  const { data: bets, loading: loading_bets } = useSubscription(BETS_LIST, {
    variables: {
      date: moment().format("YYYY-MM-DD"),
    },
  });

  const [deleteLimit] = useMutation(DELETE_LIMIT);

  const confirm = async (payload) => {
    const response = await deleteLimit({
      variables: {
        id: payload.id,
        type: payload.type,
      },
    });

    if (response?.data?.delete_betsLimits?.affected_rows) {
      notification.open({
        message: "Successfully deleted limit",
        type: "success",
      });
    } else {
      notification.open({
        message: "Failed to delete limit",
        type: "error",
      });
    }
  };

  const getRemainingLimit = (number, date = null, limit, draw = null) => {
    if (bets) {
      const drawNumber = bets.bets[0]?.drawNumber ?? 1;
      if (!date) {
        const limits = _.filter(bets.bets, (o) => {
          return o.drawNumber === drawNumber && o.number === number;
        });
        //console.log("limits", limits);
        if (limits.length) {
          return limit - limits?.[0]?.amount;
        } else {
          return limit - limits.length;
        }
      }
      if (draw) {
        if (!date) {
          const limits = _.filter(bets.bets, (o) => {
            return o.drawNumber === drawNumber && o.number === number;
          });
          if (limits.length) {
            return limit - limits?.[0]?.amount;
          } else {
            return limit - limits.length;
          }
        } else {
          if (moment(date).isSame(moment().format("YYYY-MM-DD"))) {
            const limits = _.filter(bets.bets, (o) => {
              return o.drawNumber === drawNumber && o.number === number;
            });
            //console.log("with date",limits);
            //console.log("limits2",limit - limits.length)
            if (limits.length) {
              return limit - limits?.[0]?.amount;
            } else {
              return limit - limits.length;
            }
          }
        }
      }
    }
  };

  const pageValues = {
    individual_limits,
    global_limits,
    loading_global,
    loading_individual,
    visible,
    setVisible,
    state,
    setState,
    deleteLimit,
    confirm,
    getRemainingLimit,
    loading_bets,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <LimitsView />
    </PageContext.Provider>
  );
};

export default LimitsController;
