import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Spin, Table, DatePicker, Select, Input } from "antd";
import moment from "moment";

const BetsView = () => {
  const {
    loading_daily,
    bets,
    filters,
    setFilters,
    handleSetFilter,
    agents,
    setAgents,
  } = useContext(PageContext);

  const bets_columns = [
    {
      title: "Transaction Id",
      key: "transactionId",
      render: (item) => <p>{item.transactionId}</p>,
    },
    {
      title: "Bet ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Bet Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.length - b.type.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Combination",
      dataIndex: "number",
      key: "number",
      sorter: (a, b) => a.number - b.number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Draw",
      dataIndex: "drawNumber",
      key: "drawNumber",
      sorter: (a, b) => a.drawNumber - b.drawNumber,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Agent",
      render: (item) => <p>{item.user.username}</p>,
      key: "agent",
      sorter: (a, b) => a.user.username - b.user.username,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Time",
      key: "time",
      render: (item) => (
        <p>{moment(item.created_at).format("MMMM DD,YYYY hh:mm:ss a")}</p>
      ),
      sorter: {
        compare: (a, b) =>
          moment(a.created_at)
            .format("MMMM DD,YYYY hh:mm:ss a")
            .localeCompare(
              moment(b.created_at).format("MMMM DD,YYYY hh:mm:ss a")
            ),
        multiple: 1,
      },
    },
  ];

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Bets</p>
      <div>
        <Select
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Agent User"
          onChange={(value) => handleSetFilter({ type: "agent", value })}
          className="w-56 mr-5"
        >
          {agents?.users?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.username}
            </Select.Option>
          ))}
        </Select>

        <DatePicker
          placeholder="Date from"
          className="mr-5"
          onChange={(date) =>
            handleSetFilter({ type: "date-from", value: date })
          }
        />
        <DatePicker
          placeholder="Date to"
          className="mr-5"
          onChange={(date) => handleSetFilter({ type: "date-to", value: date })}
        />

        <Select
          placeholder="Select draw"
          className="mr-5 w-32"
          value={filters.draw}
          onChange={(value) => handleSetFilter({ type: "draw", value })}
        >
          <Select.Option key="1" value="1">
            1
          </Select.Option>
          <Select.Option key="2" value="2">
            2
          </Select.Option>
          <Select.Option key="3" value="3">
            3
          </Select.Option>
        </Select>
      </div>
      <div className="mt-5">
        <Input
          placeholder="Transaction ID"
          className="w-56 mr-5"
          onChange={(value) => {
            handleSetFilter({
              type: "transactionID",
              value: value.target.value,
            });
          }}
        />
        <Input
          placeholder="Bet ID"
          className="w-56 mr-5"
          onChange={(value) =>
            handleSetFilter({
              type: "betId",
              value: value.target.value,
            })
          }
        />
        <Input
          placeholder="Combination"
          className="w-56"
          onChange={(value) =>
            handleSetFilter({
              type: "combination",
              value: value.target.value,
            })
          }
        />
      </div>
      <div className="mt-5">
        <Input
          placeholder="Min play"
          className="w-56 mr-5"
          maxLength={3}
          onChange={(value) => {
            handleSetFilter({
              type: "minPlay",
              value: value.target.value,
            });
          }}
        />
        <Input
          placeholder="Max play"
          className="w-56 mr-5"
          maxLength={3}
          onChange={(value) =>
            handleSetFilter({
              type: "maxPlay",
              value: value.target.value,
            })
          }
        />
      </div>

      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <p className="text-xs font-bold">Bets list</p>
          {loading_daily ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table dataSource={bets?.bets ?? []} columns={bets_columns} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BetsView;
