import { useMutation } from "@apollo/react-hooks";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
} from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { INSERT_RESULT } from "../../../lib/mutations";

export const AddResult = () => {
  const { visible, setVisible } = useContext(PageContext);

  const [form] = Form.useForm();

  const [insertResult] = useMutation(INSERT_RESULT);

  const handleFormFinish = async (payload) => {
    const response = await insertResult({
      variables: {
        object: { ...payload },
      },
    });
    if (response?.data?.insert_results_one?.id) {
      notification.open({
        message: "Successfully added result",
        type: "success",
      });
      form.resetFields();
      setVisible(false);
    } else {
      notification.open({
        message: "Failed to add result",
        type: "error",
      });
      setVisible(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add result"
      onCancel={() => setVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      okText="Save"
    >
      <Form form={form} onFinish={handleFormFinish} layout="vertical">
        <Form.Item
          label="Combination"
          name="combination"
          required
          rules={[{ required: true }]}
        >
          <Input
            maxLength={3}
            minLength={3}
            placeholder="Combination"
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          label="Draw number"
          name="drawNumber"
          required
          rules={[{ required: true }]}
        >
          <InputNumber
            min={1}
            max={3}
            placeholder="Draw number"
            className="w-full"
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          required
          rules={[{ required: true }]}
        >
          <DatePicker className="w-full" />
        </Form.Item>
        <Button htmlType="submit" type="primary" className="mt-5">
          Save
        </Button>
      </Form>
    </Modal>
  );
};
