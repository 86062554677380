import _ from "lodash";

export const formatMoney = (x) => {
  return new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "PHP",
  }).format(x);
};

export const calculateWinnersAndWinnings = (config, results, bets, type) => {
  let totalWinnings = 0;
  let totalWinners = 0;
  let totalSales = 0;
  let rumbleMultiplier;
  let straightMultiplier;

  if(type === "outlet" ){
    rumbleMultiplier = parseFloat(
      config.filter((item) => item.type === "outlet_winning_multiplier")[0].value
    );
    straightMultiplier = parseFloat(
      config.filter((item) => item.type === "outlet_winning_multiplier_same_digit")[0]
        .value
    );
  }else{
    rumbleMultiplier = parseFloat(
      config.filter((item) => item.type === "winning_multiplier")[0].value
    );
    straightMultiplier = parseFloat(
      config.filter((item) => item.type === "winning_multiplier_same_digit")[0]
        .value
    );
  }
  

  bets.map((item) => {
    totalSales += parseFloat(item.amount);
  });

  // check each result if it hits a bet
  results.map((result) => {
    const rumbles = bets.filter(
      (item) =>
        item.number == result.combination &&
        item.type == "Rumble" &&
        item.drawNumber == result.drawNumber
    );
    
    if (rumbles.length) {
      rumbles.map((item) => {
        const combi = item.number.split("");
        if (
          combi[0] === combi[1] ||
          combi[0] === combi[2] ||
          combi[1] === combi[2]
        ){
          totalWinnings += parseFloat((item.amount) * rumbleMultiplier);
        }
        else totalWinnings += (item.amount) * rumbleMultiplier;
      });
      totalWinners += rumbles.length;
    }

    const straights = bets.filter(
      (item) =>
        item.number == result.combination &&
        item.type == "Straight" &&
        item.drawNumber == result.drawNumber
    );
    if (straights.length) {
      straights.map((item) => {
        const combi = item.number.split("");
        if (combi[0] === combi[1] && combi[0] === combi[2])
          totalWinnings += item.amount * straightMultiplier;
        else totalWinnings += item.amount * rumbleMultiplier;
      });

      totalWinners += straights.length;
    }
  });
  return { totalWinnings: totalWinnings.toFixed(2), totalWinners, totalSales: totalSales?.toFixed(2) };
};

export const getTotalWinnersAndWinnings = (users, results, multiplier) => {
  let totalWinners = 0;
  let totalWinnings = 0;

  users &&
    users.map((user) => {
      const bets = user.bets;
      // check each result if it hits a bet
      results.map((result) => {
        const rumbles = bets.filter(
          (item) => item.number == result.combination && item.type == "Rumble"
        );

        if (rumbles.length) {
          rumbles.map((item) => {
            totalWinnings += item.amount * multiplier.rumble;
          });
          totalWinners += rumbles.length;
        }

        const straights = bets.filter(
          (item) => item.number == result.combination && item.type == "Straight"
        );
        if (straights.length) {
          straights.map((item) => {
            const combi = item.number.split("");
            if (combi[0] === combi[1] && combi[0] === combi[2])
              totalWinnings += item.amount * multiplier.straight;
            else totalWinnings += item.amount * multiplier.rumble;
          });

          totalWinners += straights.length;
        }
      });
    });

  return {
    totalWinners,
    totalWinnings,
  };
};
